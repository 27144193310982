import { cva } from 'cva';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { ArrowIcon } from './ArrowIcon';
import { EActionType, TAction } from '../../../../types';

export enum EProductCardView {
  Short = 'short',
  Extended = 'extended',
  Button = 'button',
}

const productClass =
  'items-start shrink-0 basis-60 grow-0 ' +
  'tablet:basis-[300px] tablet:grow tablet:max-w-[calc(50%-8px)] desktop:max-w-full ' +
  'font-semibold leading-extra-loose text-interface-1000 ' +
  'bg-surface-100 hover:text-interface-1000/80 group';
export const productCardCVA = cva(
  'flex min-h-40 gap-2 rounded-2xl p-6 text-lg transition-colors tablet:min-h-20 desktop:pt-7',
  {
    variants: {
      view: {
        [EProductCardView.Short]: productClass,
        [EProductCardView.Extended]: `${productClass} desktop:row-span-3`,
        [EProductCardView.Button]:
          'border-px min-w-60 basis-full items-center justify-center border border-control-200 bg-transparent text-interface-700 hover:text-interface-700/80 desktop:items-start',
      },
    },
  },
);

type TProductCardProps = {
  link?: TAction;
  view?: EProductCardView;
};

export const ProductCard: React.FC<TProductCardProps> = ({
  link,
  view = EProductCardView.Short,
}) => {
  if (!link || link.type !== EActionType.LinkType) {
    return null;
  }
  return (
    <Link
      href={link.props.href}
      target={link.props.target}
      aria-disabled={link.props.disabled}
      className={productCardCVA({ view })}
      data-ga-id={link.dataGaId}
      rel={link.props.rel}
    >
      {link.props.trailingIcon ? (
        <Image className='shrink-0' {...link.props.trailingIcon} />
      ) : null}
      {link.text}
      {view !== EProductCardView.Button ? <ArrowIcon /> : null}
    </Link>
  );
};
