export const ArrowIcon: React.FC = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='ms-auto hidden h-6 shrink-0 group-hover:brightness-0 tablet:block'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.35984 14.7682C4.93556 14.4147 4.87824 13.7841 5.2318 13.3598L9.69832 8.00002L5.2318 2.64021C4.87824 2.21593 4.93556 1.58537 5.35984 1.2318C5.78412 0.87824 6.41468 0.935563 6.76825 1.35984L11.7682 7.35984C12.0773 7.73069 12.0773 8.26936 11.7682 8.64021L6.76825 14.6402C6.41468 15.0645 5.78412 15.1218 5.35984 14.7682Z'
      fill='#B6B7BC'
    />
  </svg>
);
